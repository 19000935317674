.container {
  width: 100%;
  height: 100%;
  padding: 24px;
  background-color: #fff;
  overflow-x: hidden;
  border-radius: 10px;
}

.flex {
  display: flex;
}

.center {
  align-items: center;
}

.between {
  justify-content: space-between;
}

.around {
  justify-content: space-around;
}

.column {
  flex-direction: column;
}

.container-center {
  display: flex;
  align-items: center;
  justify-content: center;
}


.f-16px{
  font-size: 16px;
  line-height: 28px;
}


.no-wrap{
  white-space: nowrap
}

full-width{
  width: 100%;
}

.preloader-container{
  position: relative;
  flex-grow: 1;
  overflow: auto;
}
