//@import "theming.scss";

@import "assets/styles/utilities.scss";
@import "assets/styles/material.scss";
@import "primeicons/primeicons.css";

// G L O B A L S

*:not(.mat-icon, .pi, .ticket) {
  font-family: "Quicksand";
}

html {
  height: 100vh;
  // overflow-y: hidden;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background-color: #e9e9e9;
  font-family: "Quicksand", sans-serif;
  margin: 0;
  overflow: hidden;
  font-size: 14px;
  line-height: 24px;
}

html,
body {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

molecule-info-dialog {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

h1 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
}

//OUTLINE REPORTS

.outline {
  width: 100%;
  height: fit-content;
  padding: 20px;
  border: 1px solid #A8A8A8;
  border-radius: 6px;
}

//T A B S

.mat-mdc-tab-body-wrapper {
  flex-grow: 1;
  padding-right: 1px;
}


.spacer {
  flex-grow: 1;
}

.flex {
  display: flex;
}

input::-ms-reveal {
  display: none;
}