//T A B L E

table, .table{
  width: 100%;
}

th:not(.mat-calendar-table-header th){
  background-color: #E9E9E9 !important;
}


.custom-table{
  th,tr,td{
    height: 50px !important;
  }
}


// //I N P U T S

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing{
  border-color:   #3C91A6;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused:hover) .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused:hover) .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused:hover) .mdc-notched-outline .mdc-notched-outline__trailing{
  border-color:   #3C91A6;
}


.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused:hover) .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused:hover) .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused:hover) .mdc-notched-outline .mdc-notched-outline__trailing{
  border-color:   #e02323;
}


.mat-mdc-form-field-flex {
  height: 32px;
}

.mdc-text-field--no-label:not(.mdc-text-field--textarea)
.mat-mdc-form-field-input-control.mdc-text-field__input,
.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
  font-size: 14px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 4px;
}


:host ::ng-deep .mat-form-field-appearance-outline.mat-form-field-invalid .mat-form-field-outline-thick,
:host ::ng-deep .mat-form-field.mat-form-field-invalid .mat-form-field-label, .mat-error {
      color: purple !important;
}

//D I S A B L E D  I N P U T S


.mdc-text-field--disabled{
  border-radius: 5px !important;
  border: 1px solid #367D8D;
  background-color: #E9E9E9;
}


//I C O N

mat-icon{
  font-family: 'Material Icons Outlined';
  display: flex !important;
  align-items: center;
}

//D I A L O G

.dialog-container .mat-mdc-dialog-container .mdc-dialog__surface {
  display: flex;
  border-radius: 25px;
}


// T A B S

.mat-mdc-tab-body-wrapper{
  flex-grow: 1;
  padding-right: 1px;
}


.mat-mdc-tab-body-wrapper{
  flex-grow: 1;
  padding-right: 1px;
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab{
  border-bottom: 1px solid #AFCBD1;
}


// S T R O K E D  B U T T O N

.mat-mdc-outlined-button:not(:disabled){
  border: 1px solid #367d8d !important;
}

button{
  border-radius: 10px;
}



//P A G I N A T O R
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix{
  padding-top: 4px !important;
}